<template>
    <Breadcrumb page-title="Customer Info Search" page-section="Pages"> </Breadcrumb>
     <section class="content">
       <SearchContacts></SearchContacts>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
   import SearchContacts from '../components/SearchContact.vue';
   
   export default {
     name: 'Search Contact',
     components: {
       Breadcrumb,
       SearchContacts
     },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>